@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("opentype");
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* font-family: Poppins-Regular !important; */
}
@media only screen and (max-width: 1366px) {
  html,
  body,
  #root,
  .App {
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  html,
  body,
  #root,
  .App {
    height: 100%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fafbff;
}
.MuiContainer-maxWidthLg {
  padding-top: 32px;
  max-width: 1180px !important;
  padding-bottom: 32px;
}

/* .MuiButton-contained {
  border-radius: 16px !important;
} */

.MuiButton-containedSizeSmall {
  padding: 4px 24px !important;
}

.MuiButton-label {
  text-transform: capitalize;
  font-weight: 400;
}

@media only screen and (max-width: 998px) {
  .inboxContainer {
    padding: 0px !important;
  }
}

.red {
  background-color: #ee4b4b !important;
  color: #eeeff3 !important;
}

.customDropdown:focus {
  outline: none !important;
}
.customScroll {
  max-height: 600px;
  overflow-y: auto;
  margin-right: -16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 20px;
}

.customScroll::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}
.customScroll::-webkit-scrollbar-track {
  background: #ccc; /* color of the tracking area */
}
.customScroll::-webkit-scrollbar-thumb {
  background-color: #363795; /* color of the scroll thumb */
  border-radius: 20px;
}

.customScrollInboxDetail {
  max-height: 600px;
  overflow-y: auto;
  /* margin-right: -16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px; */
}

.customScrollInboxDetail::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}
.customScrollInboxDetail::-webkit-scrollbar-track {
  background: #ccc; /* color of the tracking area */
}
.customScrollInboxDetail::-webkit-scrollbar-thumb {
  background-color: #363795; /* color of the scroll thumb */
  border-radius: 20px;
}

.entitlementpersonal {
  margin-top: 4px;
  margin-bottom: 4px;
}
.entitlementPersonal::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}
.entitlementPersonal::-webkit-scrollbar-track {
  background: #ccc; /* color of the tracking area */
}
.entitlementPersonal::-webkit-scrollbar-thumb {
  background-color: #363795; /* color of the scroll thumb */
  border-radius: 20px;
}

.custDialog.MuiPopover-root {
  background-color: rgba(23, 23, 23, 0.3);
}
.custDialog.MuiPopover-root .MuiPopover-paper {
  background: transparent;
  box-shadow: none;
}
.custDialog.MuiPopover-root .MuiPopover-paper form label {
  background: #fff;
  border-radius: 4px;
  padding: 11px;
  margin-bottom: 16px;
  text-align: center;
  display: block;
}

.custDialog.MuiPopover-root .MuiPopover-paper form input {
  font-size: 14px;
}

.custDialog.MuiPopover-root
  .MuiPopover-paper
  form
  .MuiCardContent-root:last-child {
  padding-bottom: 16px;
  padding-top: 8px;
}

.appItemWrapper::-webkit-scrollbar {
  width: 4px;
}
.appItemWrapper::-webkit-scrollbar-track {
  background: #ccc;
}
.appItemWrapper::-webkit-scrollbar-thumb {
  background-color: #363795;
  border-radius: 20px;
}

.editCustDialog.MuiPopover-root {
  background-color: rgba(23, 23, 23, 0.3);
}
.editCustDialog.MuiPopover-root .MuiPopover-paper {
  box-shadow: none;
}
.editCustDialog.MuiPopover-root .MuiPopover-paper form label {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  display: block;
  margin-bottom: -16px;
}

.editCustDialog.MuiPopover-root .MuiPopover-paper form input {
  font-size: 14px;
}

.editCustDialog.MuiPopover-root
  .MuiPopover-paper
  form
  .MuiCardContent-root:last-child {
  padding-bottom: 16px;
  padding-top: 8px;
}
.appItemWrapper img:hover,
.appItemWrapper .MuiCardHeader-avatar,
.appItemWrapper .MuiCardHeader-content,
.appItemWrapper span.MuiCardHeader-title {
  background-color: transparent !important;
}
.MuiTableCell-paddingCheckbox {
  text-align: left !important;
}

.MuiTooltip-tooltip {
  background-color: #fff !important;
}

.productDivisionAccess table {
  border-collapse: separate;
  border-spacing: 0 15px;
  padding-left: 4px;
  padding-right: 4px;
}

.productDivisionAccess table .MuiTableCell-head {
  margin-bottom: 0px;
  border: 0px;
}

.productDivisionAccess table .MuiTableCell-root {
  border-bottom: none !important;
}

.productDivisionAccess table .MuiTableCell-root button:focus {
  outline: none;
}

.appImgTxt {
  display: flex;
  align-items: center;
}

.appImgTxt .MuiAvatar-root {
  margin-right: 16px;
  height: 28px;
  width: 28px;
}

.appImgTxt span {
  color: #000;
  font-weight: 500;
}

button:focus {
  outline: none !important;
}

.expandContent {
  margin-top: -13px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

a:hover {
  text-decoration: none;
}

.settingMore .MuiPopover-paper {
  width: 220px;
}

.editBtn.MuiButton-containedPrimary g circle {
  fill: #3839a2;
}

.editBtn.MuiButton-containedPrimary g g circle {
  fill: #fff;
}

.settingsDialog .MuiDialog-paper {
  width: 721px;
  max-width: none;
  height: 473px;
}
.settingsDialog .MuiDialog-paper .MuiDialogContent-root {
  padding: 0;
}

.infinite-scroll-component__outerdiv {
  margin-right: -16px;
  margin-left: -16px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .MuiGrid-justify-xs-center{
      justify-content:inherit !important;
  }
  .signup-right>div {
    width: 100% !important;
  }
  .custDialog.MuiPopover-root .MuiPopover-paper{
    width: 200px !important;
  }


  .signup-container>div {
    width: 100%;
    box-shadow: none !important;
  }

  .signup-container>div .MuiGrid-spacing-xs-3 {
    width: calc(100% + 15px);
  }
  .loginBg {
    height: 100vh;
    background-size: cover;
  }
  .signup-container .signup-right>div {
    height: 70vh;
  }
}